<template>
  <div class="flex d-flex chrismasImage">
    <div elevation="0" class="flex d-flex transparentCard white--text flex-column mx-5 mt-14 px-2 mb-2">
        <h2 class="text-center headerr mt-5">CHRISTMAS GIFT</h2>
        <v-radio-group dark v-model="defaultPack" :column="false">
            <v-radio
                key="pick"
                class="mr-4"
                label="Masculine"
                value="male"
            ></v-radio>
            <v-radio
                key="del"
                label="Feminine"
                value="female"
            ></v-radio>
        </v-radio-group>
        <div class="white--text ">
            <v-autocomplete
                v-model="values"
                @change="validateSelections"
                :items="items"
                item-text="name"
                item-value="name"
                :return-object="true"
                outlined
                dense
                dark
                label="Select Perfumes"
                
                multiple
            >
                <template v-slot:selection>
                
                </template>
                <template v-slot:item="data">
                    <v-list-item-content>
                        <v-list-item-title>{{data.item.name}}</v-list-item-title>
                        <v-list-item-subtitle>{{data.item.description}}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </div>
        <v-card outlined elevation="5" class="mx-1 mb-2 pa-2 white--text transparent opacity" v-for="item in values" :key="item.id">
            <v-row no-gutters>
                <v-col cols="11"><div>{{item.name}}</div></v-col>
                <v-col cols="1" class="text-right" @click="remove(item)"><v-icon size="18">mdi-delete</v-icon></v-col>
            </v-row>
            <div class="smalltext">{{item.description}}</div>
        </v-card>
        <v-row v-if="isValid">
            <v-spacer></v-spacer>
            <v-col class="text-right font-weight-bold" cols="4">Price: {{total}}</v-col>
        </v-row>
        <div class="white--text" v-if="isValid">
        <v-radio-group dark v-model="deliveryOption">
            <v-radio
                key="pick"
                label="Pickup Order"
                value="pickup"
            ></v-radio>
            <v-radio
                key="del"
                label="Delivery in Panjim"
                value="delivery"
            ></v-radio>
        </v-radio-group>
        <v-text-field
            outlined
            dense
            dark
            hide-details="true"
            v-if="deliveryOption === 'delivery'"
            label="Google maps link"
            v-model="mapsLink"
          ></v-text-field>
          <div v-else>
            <a href="https://maps.app.goo.gl/THMrBe64SngLEn2J8" target="_blank"><v-icon small>mdi-google-maps</v-icon>Pickup location</a>
          </div>
          <v-text-field
            outlined
            class="mt-3 mb-2"
            hide-details="true"
            dense
            dark
            label="Preferred Delivery Date and Time"
            v-model="deliveryDateTime"
          ></v-text-field>
          <v-textarea
            outlined
            class="mb-2"
            dense
            dark
            height="80"
            hide-details="true"
            label="Any note you want to write for the person"
            v-model="giftMessage"
          ></v-textarea>
          <v-alert
            outlined
            dense
            class="mt-0 px-1 textSmall"
            type="info"
            color="white"
            text
          >
            Delivery & Pickup only in Panjim.
          </v-alert>
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="4"> 
                <v-btn small class="justify-end mb-4" ><a :href="whatsappLink" target="_blank" class="black--text text-decoration-none"><v-icon color="green" class="mr-1">mdi-whatsapp</v-icon>Order</a></v-btn>
            </v-col>
        </v-row>
        </div>
    </div>

  </div>
</template>

<script>
export default {
    props: {
        items: {type: Array},
    },
    data: function() {
        return {
            values: [
                {
                  id: 49,
                  name: 'CLASSIC BLACK',
                  inspiredBy: 'JAGUAR CLASSIC BLACK',
                  price: 99,
                  new: true,
                  description: 'A bold and sophisticated scent with notes of mandarin, orange, and sandalwood, exuding confidence and style.',
              },
              {
                id: 12,
                name: 'DIOR SAUVAGE',
                inspiredBy: 'DIOR SAUVAGE',
                price: 99,
                description: 'A spicy and woody perfume with notes of pepper, bergamot, and ambergris. It has a confident and sophisticated scent that is perfect for special occasions.'
              },
              {
                id: 7,
                name: 'DAVIDOFF COOL WATER(M)',
                inspiredBy: 'DAVIDOFF COOL WATER(M)',
                price: 99,
                description: 'A fresh and aquatic perfume with notes of mint, lavender, and cedar. It has a clean and invigorating scent that is perfect for everyday wear.'
              }
            ],
            defaultPack: 'male',
            whatsappMessage: '',
            deliveryOption: 'pickup',
            mapsLink: "",
            deliveryDateTime: '',
            arrayCart: [],
            giftMessage: '',
            total: 0,
            defaultMale: [
                {
                  id: 49,
                  name: 'CLASSIC BLACK',
                  inspiredBy: 'JAGUAR CLASSIC BLACK',
                  price: 99,
                  new: true,
                  description: 'A bold and sophisticated scent with notes of mandarin, orange, and sandalwood, exuding confidence and style.',
              },
              {
                id: 12,
                name: 'DIOR SAUVAGE',
                inspiredBy: 'DIOR SAUVAGE',
                price: 99,
                description: 'A spicy and woody perfume with notes of pepper, bergamot, and ambergris. It has a confident and sophisticated scent that is perfect for special occasions.'
              },
              {
                id: 7,
                name: 'DAVIDOFF COOL WATER(M)',
                inspiredBy: 'DAVIDOFF COOL WATER(M)',
                price: 99,
                description: 'A fresh and aquatic perfume with notes of mint, lavender, and cedar. It has a clean and invigorating scent that is perfect for everyday wear.'
              }
            ],
            defaultFemale: [
              {
                id: 4,
                name: 'GUCCI FLORA',
                inspiredBy: 'GUCCI FLORA',
                price: 99,
                description: 'A floral and fruity perfume with notes of citrus, rose, and patchouli. It has a romantic and feminine scent that is perfect for everyday wear.'
              },
              {
                  id: 26,
                  name: "VS BOMBSHELL",
                  inspiredBy: 'VICTORIA\'S SECRET BOMBSHELL',
                  price: 99,
                  description: 'A vibrant and sensual perfume with notes of passionfruit, peony, and vanilla. It has a lively and alluring scent that is perfect for any occasion.'
              },
              {
                id: 15,
                name: 'ZARA BLACK',
                inspiredBy: 'ZARA BLACK',
                price: 99,
                description: 'A spicy and woody perfume with notes of black pepper, cardamom, and vetiver. It has a confident and sophisticated scent that is perfect for special occasions.'
              }
            ]
        }
    },
    watch: {
      giftMessage() {
        this.generateWhatsappText()
      },
      deliveryDateTime(){
        this.generateWhatsappText()
      },
      mapsLink(){
        this.generateWhatsappText()
      },
      deliveryOption(){
        this.generateWhatsappText()
      },
      defaultPack(nV){
        if(nV === 'male') this.values = this.defaultMale
        if(nV === 'female') this.values = this.defaultFemale
        this.validateSelections()
      },
    },
    mounted(){
        this.validateSelections()
    },
    computed: {
        isValid(){
            return this.values.length>=3 && this.values.length<=5
        },
        disabledAutocomplete(){
            return this.values.length>=5
        },
        whatsappLink() {
            return `https://wa.me/${9158186936}?text=${encodeURIComponent(this.whatsappMessage)}`;
        },
    },
    methods: {
        remove(item){
            this.values = this.values.filter(i=>i.id!==item.id)
            this.validateSelections()

        },
        validateSelections(){
            if(this.values.length>5) this.values.pop()
            this.arrayCart = this.values.map(item=> {
                return {...item, quantity: 1}
            })
            this.generateWhatsappText()
        },
        generateWhatsappText() {
            let billText = "```****Secret Santa Gift****\n\n"; // Start monospace block for WhatsApp

            // Add table headers with spacing for alignment
            billText += `No.Name           Price Qty Total\n`;
            billText += "---------------------------------\n";

            // Loop through the cart items
            this.arrayCart.forEach((item, index) => {
                const total = item.price * item.quantity;

                // Split name to fit into 12 characters
                let nameLine1 = item.name.substring(0, 12); // First line of name (max 12 chars)
                nameLine1 = nameLine1.padEnd(16)
                let nameLine2 = item.name.length > 12 ? item.name.substring(12) : ''; // Second line if name is longer

                // Prepare table rows: Align columns manually
                billText += `${index}. ${nameLine1}${item.price.toString().padEnd(6)}${item.quantity.toString().padEnd(4)}${total}\n`;
                
                // If the name is longer, add the second line of the name
                if (nameLine2) {
                    billText += `  -${nameLine2}\n`; // Indentation for second line
                }
            });

            // Add the total amount
            const totalAmount = this.arrayCart.reduce((total, item) => total + (item.price * item.quantity), 0)+ 15;
            this.total = totalAmount
            billText+="\n Packaging Charges:      Rs.15"
            billText += "\n" + `*Total:*`.padEnd(28) + totalAmount + "\n";

            // End monospace block
            billText += "```";

            if (this.deliveryOption === 'delivery') {
                billText += `\n Delivery location: ${this.mapsLink}`;
            }

            if (this.deliveryOption === 'pickup') {
                billText += `\n Pickup location: https://maps.app.goo.gl/THMrBe64SngLEn2J8`;
            }

            billText += `\n Pickup Date & Time: ${this.deliveryDateTime}`;

            billText += `\n*Gift Text:* ${this.giftMessage}`;
            

            // Assign the formatted message
            this.whatsappMessage = billText;
        }
    }

}
</script>

<style>
.chrismasImage{
    background-image: url("https://img.freepik.com/free-photo/top-view-christmas-celebration-still-life_23-2151874037.jpg?t=st=1734027514~exp=1734031114~hmac=7afba7612cb0a0e65e710ff47ecd825cab3423780f0c5a1d717e9b63ab184b00&w=740");
    background-size: cover;
    background-position: center;
}
.largeMargin{
    margin-top: 70px;
}
.smalltext{
    font-size: 9px;
    /* color: rgb(251, 255, 251);/ */
}
.transparentCard {
background: rgba(244, 228, 228, 0.38) !important;
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
backdrop-filter: blur(10px) !important;
-webkit-backdrop-filter: blur(10px) !important;
border: 1px solid rgba(244, 228, 228, 0.4) !important
}
.headerr{
    font-family: mulegh !important;
    color: rgb(253, 251, 251);
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

}

</style>