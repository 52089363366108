<template>
    <v-container class="mainProducts text-h4 mt-2 text-center backgroundColor" fluid>
        <div class="flex d-flex" v-if="activeTab===1">
          <v-text-field
            v-model="filterTerm"
            @input="onInput"
            label="Search"
            outlined
            dense
            clearable
            class="mb-0 py-0"
          ></v-text-field>  
        </div>
             
        <v-row no-gutters class="backgroundColor">
          <v-col cols="12" lg="4" class="mt-2" v-for="(perfume,index) in items" :key="index">
            <ProductCard :product="perfume" @addToCart="addToCart"></ProductCard>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ProductCard from './ProductCard.vue'

export default {
    name: 'AllProducts',
    components: {ProductCard},
    props: {
        items: {type: Array},
        activeTab: {type: Number}
    },
    data: function() {
    return {
      filterTerm: null
    }
    },
    methods: {
        addToCart(product){
           this.$emit('addToCart', product)
        },
        onInput(value){
          this.$emit('filterChanged', value)
        }
    }

}
</script>

<style>
.backgroundColor {
  background-color: rgb(243, 243, 242);
}
</style>