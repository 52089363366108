<template>
  <v-container fluid class="my-0 pa-1 justify-center align-center">
     <v-tabs
        v-model="tab"
        color="black"
        grow
        centered
        background-color="#F3F3F2"
      >
        <v-tab
          v-for="item in tabItems"
          :key="item"
          class="mx-1 pa-1"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in tabItems"
          :key="item"
          class="backgroundColor"
        >
          <component 
            :is="getComponent(item)" 
            :items="getItems(item)" 
            :activeTab="tab"
            @addToCart="addToCart" 
            :visitorCount="visitorCount"
            @filterChanged="setFilter"
          >
          </component>
        </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialog">
      <v-card class="textInter">
        <v-card-title class="text-h5">Cart</v-card-title>
        <div v-if="isCartEmpty" class="flex d-flex justify-center align-center">
          <div>Please smell good.</div>
          <div>
            <v-img
                  max-height="200"
                  max-width="200"
                  src="EmptyCart.svg"
            ></v-img>
          </div>
        </div>
        <div v-else class="px-4">
          <v-list-item v-for="(item,i) in arrayCart" :key="i*100">
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <span class="mr-4">₹{{ item.price*item.quantity }}</span>
            <v-btn class="mx-2" fab dark x-small color="black" @click="decrementQuantity(item)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <span>{{ item.quantity }}</span>
            <v-btn class="mx-2" fab dark x-small color="black" @click="incrementQuantity(item)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn class="ml-2" fab dark x-small color="red" @click="removeFromCart(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item>
          <div class="ml-4 font-weight-bold">Total: ₹{{totalCartValue}}</div>
          <v-radio-group v-model="deliveryOption">
            <v-radio
              key="pick"
              label="Pickup Order"
              value="pickup"
            ></v-radio>
            <v-radio
              key="del"
              label="Delivery in Panjim"
              value="delivery"
            ></v-radio>
          </v-radio-group>
          <v-text-field
            outlined
            v-if="deliveryOption === 'delivery'"
            label="Google maps link"
            v-model="mapsLink"
            hide-details="true"
          ></v-text-field>
          <div v-else>
            <a href="https://maps.app.goo.gl/THMrBe64SngLEn2J8" target="_blank"><v-icon small>mdi-google-maps</v-icon>Pickup location</a>
          </div>
          <v-text-field
            outlined
            class="mt-3"
            label="Preferred Delivery Date and Time"
            hide-details="true"
            v-model="deliveryDateTime"
          ></v-text-field>

          <v-checkbox
            v-model="isGift"
            label="Is this a gift for someone?"
          ></v-checkbox>
          <v-textarea
            outlined
            v-if="isGift"
            hide-details="true"
            height="80"
            label="Any note you want to write for the person"
            v-model="giftMessage"
          ></v-textarea>
          <v-alert
            outlined
            dense
            class="mt-5 pa-1 textSmall"
            type="info"
            text
          >
            Delivery & Pickup only in Panjim.
          </v-alert>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">Close</v-btn>
          <v-btn color="black" v-if="!isCartEmpty" ><a :href="whatsappLink" target="_blank" class="white--text text-decoration-none"><v-icon>mdi-whatsapp</v-icon>Order</a></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-snackbar
      v-model="notification"
      timeout="2000"
    >
      Item added to cart.
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import HomeTab from './HomeTab.vue'
import Recents from './Recents.vue'
import AllProducts from './AllProducts'
  export default {
    name: 'HomePage',
    components: {HomeTab, Recents, AllProducts},
    props: {
      dialog: {type: Boolean},
      visitorCount: {type: [Number, String]}
    },
    data: () => ({
      isGift: false,
      filterText: '',
      giftMessage: '',
      mapsLink: '',
      deliveryDateTime: '',
      deliveryOption: 'pickup',
      items: [
              {
                  id: 45,
                  name: 'LE MALE',
                  inspiredBy: 'JEAN PAUL GAULTIER LE MALE',
                  price: 99,
                  new: true,
                  description: 'A timeless and charismatic fragrance with a blend of mint, lavender, and vanilla, embodying strength and sensuality.',
              },
              {
                  id: 46,
                  name: 'LE BEAU',
                  inspiredBy: 'JEAN PAUL GAULTIER LE BEAU',
                  price: 99,
                  new: true,
                  description: 'A fresh and seductive scent with coconut wood, bergamot, and tonka bean, perfect for the modern and confident man.',
              },
              {
                  id: 47,
                  name: 'AFTERNOON SWIM',
                  inspiredBy: 'A DAY BY THE POOL',
                  price: 119,
                  new: true,
                  description: 'A vibrant and invigorating fragrance capturing the essence of a refreshing swim under the sun, with notes of aquatic accord, bergamot, and driftwood.',
              },
              {
                  id: 48,
                  name: 'CHLOE',
                  inspiredBy: 'CHLOE',
                  price: 99,
                  new: true,
                  description: 'A feminine and elegant fragrance featuring rose, peony, and lychee, enhanced by warm amber and cedarwood undertones.',
              },
              {
                  id: 49,
                  name: 'CLASSIC BLACK',
                  inspiredBy: 'JAGUAR CLASSIC BLACK',
                  price: 99,
                  new: true,
                  description: 'A bold and sophisticated scent with notes of mandarin, orange, and sandalwood, exuding confidence and style.',
              },
              {
                  id: 50,
                  name: 'CANDY CRUSH',
                  inspiredBy: 'CANDY CRUSH',
                  price: 119,
                  new: true,
                  description: 'A playful and sweet fragrance bursting with notes of cotton candy, caramel, and fruity accords, ideal for a fun and vibrant personality.',
              },
              {
                  id: 39,
                  name: 'BLACK OPIUM',
                  inspiredBy: 'YVES SAINT LAURENT BLACK OPIUM',
                  price: 120,
                  new: true,
                  description: 'A bold and seductive fragrance with rich notes of coffee, sweet vanilla, and warm spices, perfect for a modern and daring appeal.'
              },
              {
                  id: 40,
                  name: 'SPICE BOMB',
                  inspiredBy: 'VIKTOR & ROLF SPICEBOMB',
                  price: 99,
                  new: true,
                  description: 'An explosive blend of spices, including cinnamon, saffron, and chili, balanced by leather and tobacco notes for a captivating and intense scent.'
              },
              {
                  id: 41,
                  name: 'CREED AVENTUS',
                  inspiredBy: 'CREED AVENTUS',
                  price: 99,
                  new: true,
                  description: 'A bold and masculine fragrance with fruity notes of pineapple and blackcurrant, layered with smoky birch and a touch of vanilla for a powerful and distinguished aura.'
              },
              {
                  id: 42,
                  name: 'LADY MILLION',
                  inspiredBy: 'PACO RABANNE LADY MILLION',
                  price: 99,
                  new: true,
                  description: 'A luxurious and glamorous scent with sweet notes of honey, raspberry, and orange blossom, perfect for making a statement with sophistication and style.'
              },
              {
                  id: 43,
                  name: 'GUCCI OUD',
                  inspiredBy: 'GUCCI OUD',
                  price: 149,
                  new: true,
                  description: 'An exotic and sensual fragrance featuring rich oud, sweet pear, and a blend of spices, creating a warm and opulent scent with a hint of mystery.'
              },
              {
                  id: 44,
                  name: 'VERSACE BRIGHT CRYSTAL',
                  inspiredBy: 'VERSACE BRIGHT CRYSTAL',
                  price: 119,
                  new: true,
                  description: 'A fresh and vibrant fragrance with notes of pomegranate, peony, and lotus, balanced with a soft musky base, ideal for a bright and elegant presence.'
              },
              {
                  id: 38,
                  name: 'MISS DIOR',
                  inspiredBy: 'MISS DIOR',
                  price: 99,
                  description: 'A romantic and elegant perfume with notes of rose, peony, and white musk. It exudes grace and femininity, perfect for a timeless appeal.'
              },
              {
                  id: 39,
                  name: 'CAFE ROSE',
                  inspiredBy: 'TOM FORD CAFÉ ROSE',
                  price: 99,
                  description: 'An exotic and seductive perfume with notes of rose, coffee, and sandalwood. It delivers an alluring scent that is both dark and floral.'
              },
              {
                id: 30,
                name: 'CHANEL N°5',
                inspiredBy: 'CHANEL N°5',
                price: 99,
                description: 'A timeless and iconic perfume with notes of jasmine, rose, and vanilla. It embodies the essence of elegance and sophistication.'
              },
              {
                id: 31,
                name: 'AZZARO MOST WANTED',
                inspiredBy: 'AZZARO MOST WANTED',
                price: 99,
                description: 'A bold and captivating perfume with notes of cardamom, caramel, and amber. It has a rich and daring scent that leaves a lasting impression.'
              },
              {
                id: 32,
                name: 'DIOR FAHRENHEIT',
                inspiredBy: 'DIOR FAHRENHEIT',
                price: 99,
                description: 'A unique and masculine perfume with notes of leather, mandarin, and violet. It offers a daring and adventurous scent for the confident man.'
              },
              {
                id: 33,
                name: 'TOM FORD TOBACCO OUD',
                inspiredBy: 'TOM FORD TOBACCO OUD',
                price: 99,
                description: 'A smoky and rich perfume with notes of oud, tobacco, and spice. It has a bold and exotic scent that is perfect for making a statement.'
              },
              {
                id: 34,
                name: 'RASASI HAWAS',
                inspiredBy: 'RASASI HAWAS',
                price: 139,
                description: 'A fresh and aquatic perfume with notes of bergamot, cinnamon, and ambergris. It has a clean and refreshing scent that is ideal for any occasion.'
              },
              {
                id: 35,
                name: 'SHAY OUD',
                inspiredBy: 'SHAY OUD',
                price: 99,
                description: 'An opulent and rich perfume with notes of oud, rose, and amber. It has a warm and luxurious scent perfect for special events.'
              },
              {
                id: 36,
                name: 'OMBRE NOMAD',
                inspiredBy: 'LOUIS VUITTON OMBRE NOMAD',
                price: 159,
                description: 'A deep and mysterious perfume with notes of oud, incense, and leather. It offers a bold and exotic scent, ideal for the modern adventurer.'
              },
              {
                id: 37,
                name: 'MUKHALLAT OUD',
                inspiredBy: 'MUKHALLAT OUD',
                price: 149,
                description: 'A rich and traditional perfume with notes of oud, musk, and saffron. It has an intoxicating and luxurious scent that is perfect for making a grand impression.'
              },
              {
                  id: 25,
                  name: 'DIOR ELIXIR',
                  inspiredBy: 'DIOR ELIXIR',
                  price: 149,
                  description: 'A luxurious and opulent perfume with notes of rose, jasmine, and patchouli. It has a rich and sophisticated scent that is perfect for special occasions.'
              },
              {
                  id: 26,
                  name: "VS BOMBSHELL",
                  inspiredBy: 'VICTORIA\'S SECRET BOMBSHELL',
                  price: 99,
                  description: 'A vibrant and sensual perfume with notes of passionfruit, peony, and vanilla. It has a lively and alluring scent that is perfect for any occasion.'
              },
              {
                  id: 28,
                  name: 'BURJ KHALIFA',
                  inspiredBy: 'BURJ KHALIFA',
                  price: 129,
                  description: 'A bold and majestic perfume with notes of oud, saffron, and amber. It has a powerful and sophisticated scent that is perfect for making a statement.'
              },
              {
                  id: 29,
                  name: 'CALIBER',
                  inspiredBy: 'CALIBER',
                  price: 99,
                  description: 'A refined and elegant perfume with notes of bergamot, cedar, and musk. It has a classic and timeless scent that is perfect for everyday wear.'
              },
              {
                id: 1,
                name: 'DIPTYQUE TAM DAO',
                inspiredBy: 'DIPTYQUE TAM DAO',
                price: 99,
                description: 'A woody and floral perfume with notes of Vietnamese oud, sandalwood, and rose. It has a warm and inviting scent that is both sophisticated and comforting.'
              },
              {
                id: 2,
                name: 'LACOSTE WHITE',
                inspiredBy: 'LACOSTE WHITE',
                price: 99,
                description: 'A fresh and citrusy perfume with notes of yuzu, bergamot, and cedar. It has a clean and invigorating scent that is perfect for everyday wear.'
              },
              {
                id: 3,
                name: 'DUNHILL ICON',
                inspiredBy: 'DUNHILL ICON',
                price: 99,
                description: 'A spicy and woody perfume with notes of black pepper, cardamom, and vetiver. It has a confident and sophisticated scent that is perfect for special occasions.'
              },
              {
                id: 4,
                name: 'GUCCI FLORA',
                inspiredBy: 'GUCCI FLORA',
                price: 99,
                description: 'A floral and fruity perfume with notes of citrus, rose, and patchouli. It has a romantic and feminine scent that is perfect for everyday wear.'
              },
              {
                id: 5,
                name: 'BLEU DE CHANEL',
                inspiredBy: 'BLEU DE CHANEL',
                price: 99,
                description: 'A woody and aromatic perfume with notes of grapefruit, juniper, and cedar. It has a fresh and invigorating scent that is perfect for everyday wear.'
              },
              {
                id: 6,
                name: 'ARMANI BLACK CODE',
                inspiredBy: 'ARMANI BLACK CODE',
                price: 99,
                description: 'A woody and spicy perfume with notes of coffee, vanilla, and tonka bean. It has a warm and inviting scent that is perfect for special occasions.'
              },
              {
                id: 7,
                name: 'DAVIDOFF COOL WATER(M)',
                inspiredBy: 'DAVIDOFF COOL WATER(M)',
                price: 99,
                description: 'A fresh and aquatic perfume with notes of mint, lavender, and cedar. It has a clean and invigorating scent that is perfect for everyday wear.'
              },
              {
                id: 8,
                name: 'DAVIDOFF COOL WATER(W)',
                inspiredBy: 'DAVIDOFF COOL WATER(W)',
                price: 99,
                description: 'A fresh and floral perfume with notes of lotus, jasmine, and sandalwood. It has a clean and invigorating scent that is perfect for everyday wear.'
              },
              {
                id: 9,
                name: 'GUCCI GUILTY',
                inspiredBy: 'GUCCI GUILTY',
                price: 99,
                description: 'A fruity and floral perfume with notes of peach, rose, and patchouli. It has a romantic and feminine scent that is perfect for everyday wear.'
              },
              {
                id: 10,
                name: 'ONE MILLION',
                inspiredBy: 'ONE MILLION',
                price: 99,
                description: 'A spicy and woody perfume with notes of grapefruit, cinnamon, and leather. It has a confident and sophisticated scent that is perfect for special occasions.'
              },
              {
                id: 11,
                name: 'ACQUA DI GIO',
                inspiredBy: 'ACQUA DI GIO',
                price: 99,
                description: 'A fresh and aquatic perfume with notes of citrus, marine notes, and patchouli. It has a clean and invigorating scent that is perfect for everyday wear.'
              },
              {
                id: 12,
                name: 'DIOR SAUVAGE',
                inspiredBy: 'DIOR SAUVAGE',
                price: 99,
                description: 'A spicy and woody perfume with notes of pepper, bergamot, and ambergris. It has a confident and sophisticated scent that is perfect for special occasions.'
              },
              {
                id: 13,
                name: 'ADDICTION',
                inspiredBy: 'ADDICTION',
                price: 99,
                description: 'A floral and fruity perfume with notes of rose, jasmine, and amber. It has a romantic and feminine scent that is perfect for everyday wear.'
              },
              {
                id: 14,
                name: 'CR7',
                inspiredBy: 'CR7',
                price: 99,
                description: 'A fresh and sporty perfume with notes of citrus, lavender, and cedar. It has a clean and invigorating scent that is perfect for everyday wear.'
              },
              {
                id: 15,
                name: 'ZARA BLACK',
                inspiredBy: 'ZARA BLACK',
                price: 99,
                description: 'A spicy and woody perfume with notes of black pepper, cardamom, and vetiver. It has a confident and sophisticated scent that is perfect for special occasions.'
              },
              {
                id: 16,
                name: 'TITAN SKINN',
                inspiredBy: 'TITAN SKINN',
                price: 99,
                description: 'A fresh and aquatic perfume with notes of citrus, marine notes, and patchouli. It has a clean and invigorating scent that is perfect for everyday wear.'
              },
              {
                id: 17,
                name: 'MUSK SAFI',
                inspiredBy: 'MUSK SAFI',
                price: 149,
                description: 'A warm and musky perfume with notes of amber, musk, and vanilla. It has a comforting and inviting scent that is perfect for everyday wear.'
              },
              {
                id: 18,
                name: 'TOM FORD OMBRE LEATHER',
                inspiredBy: 'TOM FORD OMBRE LEATHER',
                price: 129,
                description: 'A smoky and leathery perfume with notes of tobacco, leather, and vanilla. It has a bold and confident scent that is perfect for special occasions.'
              },
              {
                id: 19,
                name: 'BYREDO GYPSY WATER',
                inspiredBy: 'BYREDO GYPSY WATER',
                price: 129,
                description: 'A woody and floral perfume with notes of pine needles, birch, and incense. It has a fresh and invigorating scent that is perfect for everyday wear.'
              },
              {
                id: 20,
                name: 'CHANEL ALLURE SPORT',
                inspiredBy: 'CHANEL ALLURE SPORT',
                price: 129,
                description: 'A fresh and citrusy perfume with notes of grapefruit, mandarin orange, and cedar. It has a clean and invigorating scent that is perfect for everyday wear.'
              },
              {
                id: 21,
                name: 'TOM FORD OUD WOOD',
                inspiredBy: 'TOM FORD OUD WOOD',
                price: 129,
                description: 'A warm and woody perfume with notes of oud, patchouli, and vanilla. It has a rich and luxurious scent that is perfect for special occasions.'
              },
              {
                id: 22,
                name: 'LATTAFA OUD MOOD',
                inspiredBy: 'LATTAFA OUD MOOD',
                price: 129,
                description: 'A warm and spicy perfume with notes of oud, saffron, and amber. It has a rich and luxurious scent that is perfect for special occasions.'
              },
              {
                id: 23,
                name: 'TOM FORD AMBER OUD',
                inspiredBy: 'TOM FORD AMBER OUD',
                price: 129,
                description: 'A warm and spicy perfume with notes of amber, oud, and vanilla. It has a rich and luxurious scent that is perfect for special occasions.'
              },
              {
                id: 24,
                name: 'HUGO BOSS',
                inspiredBy: 'HUGO BOSS',
                price: 99,
                description: 'A fresh and aromatic perfume with notes of apple, mint, and cedar. It has a clean and invigorating scent that is perfect for everyday wear.'
              }
      ],
      tab: null,
      tabItems: ['Home', 'All', 'New Launches'],
      cart: {},
      notification: false,
      whatsappMessage: '',
      tabComponentMap: {
        Home: 'HomeTab',
        'New Launches': 'AllProducts',
        All: 'AllProducts'
      }
    }),
    watch: {
      arrayCart(nV){
        const len = nV.length
        this.$emit('cartSize', len)
        this.generateWhatsappText()
        return len
      },
      isGift(){
        this.giftMessage = ''
      },
      giftMessage() {
        this.generateWhatsappText()
      },
      deliveryDateTime(){
        this.generateWhatsappText()
      },
      mapsLink(){
        this.generateWhatsappText()
      },
      deliveryOption(){
        this.generateWhatsappText()
      },
    },
    computed: {
      recentProducts() {
        return this.items.filter(i=>i.new)
      },
      whatsappLink() {
      return `https://wa.me/${9158186936}?text=${encodeURIComponent(this.whatsappMessage)}`;
      },
      arrayCart(){
        return Object.values(this.cart)
      },
      isCartEmpty(){
        return this.arrayCart.length === 0
      },
      totalCartValue(){
        return this.arrayCart.reduce((acc,item)=>item.price*item.quantity+acc, 0 )
      }
    },
    methods: {
      setFilter(value){
        this.filterText = value
      },
      getItems(item){
        if(item === 'All'){
          if(this.filterText){    
            // Escape special characters in filterText to prevent regex errors
            const escapedFilterText = this.filterText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

            // Create a case-insensitive regex for partial matching
            const regex = new RegExp(escapedFilterText, 'i');

            return this.items.filter(perfume => regex.test(perfume.name) || regex.test(perfume.description)); 
          }
          return this.items
        } 
        return this.recentProducts
      },
      getComponent(key){
        return this.tabComponentMap[key]
      },
      addToCart(perfume){
         Vue.set(this.cart,perfume.id,{...perfume, quantity: 1})
         this.notification = true
      },
      removeFromCart(perfume){
        Vue.delete(this.cart, perfume.id);
      },
      closeDialog(){
        this.$emit('closeDialog')
      },
      decrementQuantity(perfume){
        const currentQuantity = perfume.quantity
        if(currentQuantity<=1) return
        Vue.set(this.cart,perfume.id,{...perfume, quantity: perfume.quantity - 1})
      },
      incrementQuantity(perfume){
        Vue.set(this.cart,perfume.id,{...perfume, quantity: perfume.quantity + 1})
      },
      generateWhatsappText() {
        let billText = "```        ****My Order****\n\n"; // Start monospace block for WhatsApp

        // Add table headers with spacing for alignment
        billText += `No.Name           Price Qty Total\n`;
        billText += "---------------------------------\n";

        // Loop through the cart items
        this.arrayCart.forEach((item, index) => {
            const total = item.price * item.quantity;

            // Split name to fit into 12 characters
            let nameLine1 = item.name.substring(0, 12); // First line of name (max 12 chars)
            nameLine1 = nameLine1.padEnd(16)
            let nameLine2 = item.name.length > 12 ? item.name.substring(12) : ''; // Second line if name is longer

            // Prepare table rows: Align columns manually
            billText += `${index}. ${nameLine1}${item.price.toString().padEnd(6)}${item.quantity.toString().padEnd(4)}${total}\n`;
            
            // If the name is longer, add the second line of the name
            if (nameLine2) {
                billText += `  -${nameLine2}\n`; // Indentation for second line
            }
        });

        // Add the total amount
        const totalAmount = this.arrayCart.reduce((total, item) => total + (item.price * item.quantity), 0);
        billText += "\n" + `*Total:*`.padEnd(28) + totalAmount + "\n";

        // End monospace block
        billText += "```";

        if (this.deliveryOption === 'delivery') {
            billText += `\n Delivery location: ${this.mapsLink}`;
        }

        if (this.deliveryOption === 'pickup') {
            billText += `\n Pickup location: https://maps.app.goo.gl/THMrBe64SngLEn2J8`;
        }

        billText += `\n Pickup Date & Time: ${this.deliveryDateTime}`;

        if (this.isGift) {
            billText += `\n*Gift Text:* ${this.giftMessage}`;
        }

        // Assign the formatted message
        this.whatsappMessage = billText;
    }

    }
  }
</script>
<style>
.introText {
  font-family: inter;
  font-size: 15px;
  font-weight: 500;
}
.perfumeName {
  font-family: geologica;
  font-size: 16px;
  font-weight: 600;
  max-height: 35px;
}
.inspiredBy {
  font-family: inter;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
}
.descriptionText {
  font-family: inter;
  font-size: 13px;
  line-height: 1.2;
  word-break: break-all;
}
.textInter {
    font-family: inter;
}
.headerProducts {
    font-size: 24px;
    font-weight: 500;
    font-family: inter;

}
.textSmall{
  font-size: 14px;
  font-weight: 500;
  font-family: inter;
}
</style>
