<template>
  <v-card
    class="text-center pa-1 pr-2 mx-1"
    >
    <v-row>
        <v-col cols="3" >
            <v-img
            max-height="230"
            min-height="200"
            max-width="90"
            aspect-ratio="1/5"
            :src="`./${product.name}.jpg`"
            ></v-img>
        </v-col>
        <v-col cols="9" class="text-left">
            <p class="inspiredBy mb-1">INSPIRED BY</p>
            <div class="perfumeName"> {{product.name}}</div>
            <div class="descriptionText  mt-2">{{product.description}}</div>
            <div class="descriptionText font-weight-bold text-left mt-2">₹ {{product.price}} / 10ml</div>
            <div class="descriptionText mt-1">Available in perfume and ittar forms. The prices of ittar may vary.</div>
            <v-card-actions class="mt-1">
            <v-row class="text-right">
                <v-spacer></v-spacer>
                <v-col class="">
                <v-btn  color="black" class="ml-4 white--text pa-2" @click="addToCart(product)">Add to cart</v-btn>
                </v-col>
            </v-row>
            </v-card-actions>
        </v-col>
    </v-row>         
</v-card>
</template>

<script>
export default {
    props: {
        product: {type: Object}
    },
    methods: {
        addToCart(product){
           this.$emit('addToCart', product)
        }
    }

}
</script>

<style>

</style>